<template>
  <div class="refund-dialog">
    <w-dialog ref="dialogRef" :title="dialogTitle" width="50%" top="4vh" @wConfirm="handleSure"
      :confirmText="dialogTitle">
      <div class="ticket-content">
        <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item prop="refund_money" label="退款金额">
                <KeyBoard ref="keyBoardRef" v-model="formData.refund_money" :type="'number'" :placeholder="'请输入退款金额'"
                  @inputEvent="numberChange" :isShowAppend="true" @onShowKey="onShowKey"></KeyBoard>
                <!-- <el-input
               :disabled="isDisabled"
                v-model="formData.refund_money"
                autocomplete="off"
                type="number"
                placeholder="请输入退款金额"
                @change="numberChange"
                clearable
              >
                <template #append>元</template></el-input
              > -->
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item prop="refund_reason" label="退款备注">
                <KeyBoard ref="keyBoardNotesRef" v-model="formData.refund_reason" :type="'textarea'"
                  :placeholder="'请输入退款备注'" @onShowKey="onNotesShowKey"></KeyBoard>
                <!-- <el-input
                type="textarea"
                rows="3"
                placeholder="请输入退款备注"
                v-model="formData.refund_reason"
              ></el-input> -->
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="线上操作" prop="is_offline_refund">
                <el-radio-group v-model="formData.is_offline_refund">
                  <el-radio :label="0">原路返还</el-radio>
                  <el-radio :label="1">人工退款</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="font-gray" v-if="!isDisabled">退款金额在0到{{ maxMoney }}之间</div>
        </el-form>
      </div>
    </w-dialog>
  </div>
</template>

<script>
  import { ref, nextTick } from "vue";
  import KeyBoard from "@/components/key-board/keyboard.vue";
  // import { ElMessage } from "element-plus";

  export default {
    emits: ["submit"],
    components: {
      KeyBoard,
    },

    setup(props, { emit }) {
      const formData = ref({
        refund_money: "",
        refund_reason: "",
        is_offline_refund: 0,
      });

      const dialogRef = ref(null);
      const formRules = {
        refund_money: [
          {
            required: true,
            message: "请输入退款金额",
            trigger: "blur",
          },
        ],

        is_offline_refund: [
          {
            required: true,
            message: "请选择是否为线上操作",
            trigger: "blur",
          },
        ],
      };
      const dialogTitle = ref("");
      const maxMoney = ref(null);
      function numberChange(val) {
        const currentNumber = +val;
        nextTick(() => {
          if (currentNumber <= 0 || currentNumber > maxMoney.value) {
            formData.value.refund_money = maxMoney.value;
          }
        });
      }
      const isDisabled = ref(false);
      const keyBoardRef = ref(null);  // 软键盘对象
      const keyBoardNotesRef = ref(null);  // 备注软键盘对象
      function openDialog(title, money, refundMoney, type) {
        isDisabled.value = type === "agent" ? true : false;
        maxMoney.value = +money;
        dialogTitle.value = title;
        formData.value = {
          refund_money: refundMoney,
          refund_reason: "",
          is_offline_refund: 0,
        };
        dialogRef.value.show();
        nextTick(() => {
          keyBoardRef.value.clickKey(refundMoney);
        })
      }
      /**
       * 
       * 关闭备注软键盘
       * 
       * **/
      function onShowKey() {
        keyBoardNotesRef.value.closeKey();
      }
      /**
       * 
       * 关闭退款金额软键盘
       * 
       * **/
      function onNotesShowKey() {
        keyBoardRef.value.closeKey();
      }
      function closeDialog() {
        dialogRef.value.close();
      }
      function closeDialogLoading() {
        dialogRef.value.isLoading = false;
      }
      const formRef = ref(null);
      function handleSure() {
        if (formRef.value) {
          formRef.value.validate((valid) => {
            if (valid) {
              dialogRef.value.isLoading = true;
              const data = JSON.parse(JSON.stringify(formData.value));

              emit("submit", data);
            }
          });
        }
      }
      return {
        formData,
        formRules,
        formRef,
        handleSure,
        openDialog,
        dialogRef,
        closeDialog,
        dialogTitle,
        numberChange,
        maxMoney,
        closeDialogLoading,
        isDisabled,
        keyBoardRef,
        keyBoardNotesRef,
        onShowKey,
        onNotesShowKey,
      };
    },
  };
</script>

<style lang="scss">
  .refund-dialog {

    .el-input-group__append,
    .el-input-group__prepend {
      border: none;
      background-color: var(--search-uncheck-bg-color) !important;
      color: var(--text-gray-color);
    }

    .el-input-group__append {
      border-left: 1px solid #e6e3e3;
    }

    .img-upload>ul li {
      height: 40px;
      width: 40px;
      line-height: 40px;
    }

    .el-form-item {
      .el-form-item__content {
        .el-image {
          width: 40px !important;
          height: 40px !important;
        }
      }
    }

    .ticket-content {
      min-height: 450px;
    }
  }
</style>