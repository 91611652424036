<template>
  <div class="hotel-check-dialog">
    <w-dialog
      ref="dialogRef"
      title="修改入住时间"
      width="20%"
      top="25vh"
      @wConfirm="handleSure"
    >
      <el-form
        ref="formRef"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <!-- :label="'入住时间（可延期：' + postponeDay + '天）'" -->
            <el-form-item prop="hotel_time" label="入住时间">
              <el-date-picker
                v-model="formData.hotel_time"
                type="date"
                placeholder="请选择开始入住时间"
                value-format="YYYY-MM-DD"
              >
              </el-date-picker>
              <!-- :disabled-date="disabledDate" -->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </w-dialog>
  </div>
</template>

<script>
import { ref } from "vue";
import { ElMessage } from "element-plus";
import { OrderApi } from "@/plugins/api.js";
import dayjs from "dayjs";
export default {
  emits: ["reloadTable"],
  setup(props, { emit }) {
    const formData = ref({
      hotel_time: "",
    });

    const dialogRef = ref(null);
    const formRules = {
      hotel_time: [
        {
          required: true,
          message: "请选择开始入住日期",
          trigger: "blur",
        },
      ],
    };
    const orderSn = ref(null);
    const disabledDate = (value) => {
      return (
        value.getTime() < dayjs(dayjs().format("YYYY-MM-DD")).valueOf() ||
        value.getTime() > dayjs(dayjs().add(postponeDay.value, "day")).valueOf()
      );
    };
    const postponeDay = ref(null);
    function openDialog(data) {
      postponeDay.value = +data.h_ext_day;
      formData.value.hotel_time = data.oh_reservation_date;
      orderSn.value = data.sn;
      dialogRef.value.show();
    }
    function closeDialog() {
      dialogRef.value.close();
    }
    function closeDialogLoading() {
      dialogRef.value.isLoading = false;
    }
    const formRef = ref(null);
    function handleSure() {
      if (formRef.value) {
        formRef.value.validate((valid) => {
          if (valid) {
            dialogRef.value.isLoading = true;
            OrderApi.editCheckTime({
              sn: orderSn.value,
              ...formData.value,
            }).then((res) => {
              closeDialogLoading();
              if (res.Code === 200) {
                ElMessage.success(`操作成功！`);
                closeDialog();
                emit("reloadTable", true);
              } else {
                let msg = res.Message ? res.Message : `操作失败！`;
                ElMessage.error(msg);
              }
            });
          }
        });
      }
    }
    return {
      formData,
      formRules,
      formRef,
      handleSure,
      openDialog,
      dialogRef,
      closeDialog,
      closeDialogLoading,
      disabledDate,
      postponeDay,
    };
  },
};
</script>

<style lang="scss">
.hotel-check-dialog {
  .el-input-number {
    width: 100%;
  }
}
</style>
